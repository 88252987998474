<template>
    <div class="container">
        <div class="head">
            <div class="arrow" @click="back"><van-icon size="24" name="arrow-left" /></div>签署合同
        </div>
        <div class="content" v-if="contractUrl !== ''">
            <!-- <iframe :src="contractUrl" width="100%" height="700px"></iframe> -->
             <!-- <img src="/dist/640.jpg" alt=""> -->
            <PDF :url="contractUrl" />
        </div>
        <div v-if="acceptStatus === 0" @click="acceptContacts" class="accept">接受并签署</div>
    </div>
</template>
<script setup>
import PDF from '@/components/pdf/index.vue'
import { useRouter } from 'vue-router'
import { ref, onMounted } from 'vue'; 
import { get, post } from '@/utils/http';
import { showToast, showLoadingToast } from 'vant';

const router = useRouter()
const contractUrl = ref('')
const acceptStatus = ref()
const back = () => {
    router.back()
}


const getContacts = async () => {
    try {

        const res = await get('/v1/api/contacts_url');
        console.log(res, '合同信息');
        acceptStatus.value = res.signing_status
        contractUrl.value = res.contract_url
        console.log(contractUrl.value);
    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const postContacts = async () => {
    try {
        showLoadingToast({
            message: '正在签约中！',
            forbidClick: true,
            duration: 0
        });

        const res = await post('/v1/api/contacts_sign');

        showToast(res.resp_message);
        if (res.code === 1) {

            setTimeout(() => {
                router.push('/')
            }, 2000)
        }
        if (res.code === 0) {
            setTimeout(() => {
                router.push('/')
            }, 2000)
        }

    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const acceptContacts = () => {
    postContacts()
}
onMounted(() => {
    getContacts()
})
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100vh;
    background-color: rgba(255, 255, 255, 1);

    .head {
        height: 50px;
        line-height: 50px;
        box-sizing: border-box;
        text-align: center;
        background-color: rgba(245, 247, 250, 1);
        position: relative;

        .arrow {
            position: absolute;
            width: 10%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }
    }

    .content {
        height: 100%;
        box-sizing: border-box;
        padding: 0px 12px;

        img {
            width: 100%;
            height: 500px;
        }

    }

    .accept {
        height: 50px;
        line-height: 50px;
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #266EFF;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        text-align: center;
    }

    p {
        margin-bottom: 0;
        text-indent: 2em;
    }
}
</style>