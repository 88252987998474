<template>
    <div class="container">
        <div class="balance">
            <div class="money">
                <div class="title">账户余额（元）</div>
                <div class="total">{{ mount }}</div>
            </div>
            <div @click="confirmPayouts" class="Payouts">提现</div>
        </div>
        <div class="water">
            <van-tabs v-model:active="active">
                <van-tab title="入账">
                    <div class="pickTime">
                        <div class="leftName">日期</div>
                        <div class="timeIco"><van-icon size="24" name="calendar-o" /></div>
                        <div @click="chooseTime(1)" class="startTime">{{ startTime === '' ? '起始日期' : startTime }}</div>
                        <div class="line"></div>
                        <div @click="chooseTime(2)" class="endTime">{{ endTime === '' ? '结束日期' : endTime }}</div>
                    </div>
                    <div class="waterBox">
                        <div @click="goDetail(item)" class="waterInfo" v-for="(item, index) in accountList">
                            <div class="head">
                                <div class="name">{{ item.khmc }}</div>
                                <div class="number">{{ item.khbm }}</div>
                                <div class="total">+{{ item.amount }}</div>
                            </div>
                            <div class="foot">
                                <div class="time">{{ item.created_time }}</div>
                                <div v-if="item.status === 0" class="status" style="color: red;">未提现</div>
                                <div v-else-if="item.status === 1" class="status" style="color: red;">提现中</div>
                                <div v-else-if="item.status === 2" class="status" style="color: blue;">已提现</div>
                            </div>
                        </div>
                    </div>
                </van-tab>
                <van-tab title="提现">
                    <div class="pickTime">
                        <div class="leftName">日期</div>
                        <div class="timeIco"><van-icon size="24" name="calendar-o" /></div>
                        <div @click="chooseTime(1)" class="startTime">{{ startTime === '' ? '起始日期' : startTime }}</div>
                        <div class="line"></div>
                        <div @click="chooseTime(2)" class="endTime">{{ endTime === '' ? '结束日期' : endTime }}</div>
                    </div>
                    <div class="waterBox">
                        <div @click="goDetail(item)" class="waterInfo" v-for="(item, index) in cashList">
                            <div class="head">
                                <div class="name">{{ item.name }}</div>
                                <div class="total">-{{ item.amount }}</div>
                            </div>
                            <div class="foot">
                                <div class="time">{{ item.created_time }}</div>
                                <div v-if="item.status === 50" class="status" style="color: blue;">下发成功</div>
                                <div v-else-if="item.status === -10 || item.status === -15" class="status"
                                    style="color: red;">下发失败 </div>
                                <div v-else class="status" style="color: red;">提现中</div>
                            </div>
                        </div>
                    </div>
                </van-tab>
            </van-tabs>
        </div>
        <van-popup v-model:show="showTime" position="bottom">
            <van-date-picker :min-date="minDate" v-model="currentDate" title="选择日期" @cancel="cancel"
                @confirm="confirm" />
        </van-popup>
    </div>

</template>
<script setup>
import { get, post } from '@/utils/http';
import { showConfirmDialog, showToast, DatePicker, Popup } from 'vant';
import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router'
import { showSuccessToast, showFailToast } from 'vant';
const router = useRouter()
const minDate = new Date(2020, 0, 1)
const mount = ref(0)
const userInfo = ref({})
const accountList = ref([])
const cashList = ref([])
const timeIndex = ref()
const startTime = ref('')
const endTime = ref('')
const showTime = ref(false)
const currentDate = ref([]);
const acceptStatus = ref()
const confirmPayouts = () => {
    let cardNoLastFourDigits = userInfo.value.card_no.slice(-4); // 截取后四位
    showConfirmDialog({
        title: '确认提现',
        message:
            `即将账户余额全部提现至银行账户(${cardNoLastFourDigits})\n提现金额：${mount.value}元`,
    })
        .then(() => {
            // on confirm
            console.log(acceptStatus.value);
            if (acceptStatus.value === 0) {
                showFailToast('合同未签约');
                setTimeout(() => {
                    router.push("/contract")
                }, 2000);
                return
            }
            postCash().then(() => {
                getUserMoney()
                getAccountList()
                getCashList()
            })
        })
        .catch(() => {
            // on cancel
        });
}
const chooseTime = (index) => {
    console.log(index);
    timeIndex.value = index
    showTime.value = true
}
const cancel = () => {
    showTime.value = false
}
const confirm = () => {
    const timeSting = currentDate.value.join('-')
    if (timeIndex.value === 1) {
        startTime.value = timeSting
    }
    if (timeIndex.value === 2) {
        if (startTime.value === '') {
            showFailToast('请选择起始日期');
            return
        }
        endTime.value = timeSting
       
        getAccountList()
        getCashList()
        console.log(startTime.value, endTime.value);
    }
    showTime.value = false
   
}
const postCash = async () => {
    try {

        const res = await post('/v1/api/cash', {
            money: mount.value
        });
        console.log(res);
        showToast(res.message);

    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const getInfo = async () => {
    try {
        const params = {
            mobile: '15300080661',
            code: '9999',
            customer: '1'
        }
        const res = await get('/v1/api/login', params);


    } catch (error) {
        console.error(error); // 处理错误  
    }
}
// 金额
const getUserMoney = async () => {

    get('/v1/api/user_amount')
        .then(res => {

            mount.value = res.amount
        })
        .catch(error => {
            console.error('An error occurred:', error); // 处理错误  
        });

}
// 流水
const getAccountList = async () => {
    try {

        const res = await get('/v1/api/account_list', {
            page_no: 1,
            start_time: startTime.value,
            end_time: endTime.value
        });

        accountList.value = res.list
        console.log(accountList.value);
    } catch (error) {
        console.error(error); // 处理错误  
    }
}
// 提现记录
const getCashList = async () => {
    try {

        const res = await get('/v1/api/cash_list', {
            page_no: 1,
            start_time: startTime.value,
            end_time: endTime.value
        });
        cashList.value = res.list
        console.log(cashList.value, 555);

    } catch (error) {
        console.error(error); // 处理错误  
    }
}
// 个人信息

const getUserInfo = async () => {
    try {

        const res = await get('/v1/api/user_info',);
        userInfo.value = res.user_info

        if (res.user_info.card_no === '') {
            router.push('/refine')
            return
        }


    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const getContacts = async () => {
    try {

        const res = await get('/v1/api/contacts_url');

        acceptStatus.value = res.signing_status


    } catch (error) {
        console.error(error); // 处理错误  
    }
}
const goDetail = (item) => {
    console.log(item);
    router.push({ path: '/management/accountdetail', query: { name: item.khmc, amount: item.amount, time: item.created_time, number: item.uuid, task: item.rwmc, bank: item.name, status: item.status, khbm: item.khbm, rwnr: item.rwnr } });
}

onMounted(() => {
    // 获取用户金额
    getUserMoney()
    // 测试专用
    // getInfo()
    // 入账列表
    getAccountList()
    // 流水列表
    getCashList()
    // 获取用户个人信息
    getUserInfo()
    // 获取合同状态
    getContacts()
})


const active = ref(0);
</script>

<style scoped lang="less">
.container {
    width: 100%;
    height: 100vh;
    background-color: rgba(245, 247, 250, 1);
    padding-top: 20px;

    .balance {
        width: 85%;
        height: 122px;
        background-color: rgba(38, 110, 255, 1);
        border-radius: 15px;
        margin: 0 auto;
        display: flex;

        .money {
            padding-top: 21px;

            font-weight: 600;
            font-family: PingFangSC-bold;
            color: rgba(245, 247, 250, 1);

            .title {
                font-size: 16px;
                padding-left: 30px;

            }

            .total {
                margin-top: 6px;
                padding-left: 30px;
                font-size: 36px;

            }
        }

        .Payouts {
            margin: auto 0;
            margin-left: 24%;
            width: 77px;
            height: 35px;
            line-height: 35px;
            text-align: center;
            border-radius: 15px;
            background-color: rgba(245, 247, 250, 1);
            color: rgba(38, 110, 255, 1);
            font-size: 20px;
        }

    }

    .water {
        margin-top: 15px;

        .pickTime {
            background-color: rgba(255, 255, 255, 1);
            margin-top: 4px;
            height: 40px;
            line-height: 40px;
            padding-left: 25px;
            display: flex;

            .leftName {}

            .timeIco {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 12px;

            }

            .startTime {
                margin: auto 0;
                margin-left: 10px;
                height: 26px;
                width: 90px;
                border: 1px solid #bebebe;
                border-radius: 3px;
                text-align: center;
                line-height: 26px;
                color: #9d9898;
                font-size: 14px;
            }

            .line {
                width: 20px;
                height: 1px;
                margin: auto 0;
                margin-left: 10px;
                background-color: #bebebe;
            }

            .endTime {
                margin: auto 0;
                margin-left: 10px;
                height: 26px;
                width: 90px;
                border: 1px solid #bebebe;
                border-radius: 3px;
                text-align: center;
                line-height: 26px;
                color: #9d9898;
                font-size: 14px;
            }
        }

        .waterBox {
            overflow-y: auto;
            padding-bottom: 54px;

            .waterInfo {
                height: 71px;
                box-sizing: border-box;
                background-color: rgba(255, 255, 255, 1);
                margin-top: 4px;
                padding: 9px 13px;

                .head {
                    display: flex;
                    justify-content: space-between;

                    .name {
                        font-size: 16px;

                    }

                    .number {
                        margin-right: 80px;
                        font-size: 14px;
                        margin-top: 3px;

                    }

                    .total {
                        font-size: 18px;
                    }
                }

                .foot {
                    margin-top: 8px;
                    font-size: 16px;
                    display: flex;
                    justify-content: space-between;

                    .time {}
                }
            }
        }
    }
}
</style>