<template>
  <div class="menu" v-if="!$route.meta.hideNav">
    <van-tabbar v-model="active">
      <van-tabbar-item replace to="/" icon="credit-pay">账户管理</van-tabbar-item>
      <van-tabbar-item replace to="/individual" icon="contact-o">我的</van-tabbar-item>
    </van-tabbar>
  </div>
  <router-view />
</template>

<script setup>
import { ref, onMounted, watchEffect } from 'vue';
import { useRoute } from 'vue-router';
const active = ref(0)
const acceptStatus = ref()
const route = useRoute(); // 获取当前路由的实例
const getContacts = async () => {
  try {

    const res = await get('/v1/api/contacts_url');

    acceptStatus.value = res.signing_status
    if (res.signing_status === 1) {
      router.push('/contract')
      return
    }

  } catch (error) {
    console.error(error); // 处理错误  
  }
}
watchEffect(() => {
  if (route.path === '/') {
    active.value = 0;
  }
})
onMounted(() => {
 
})

</script>

<style scoped lang="less">
.menu {}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
